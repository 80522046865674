import React from "react";

import PageWrapper from "../../components/PageWrapper";
import CTA from "../../components/CTA/CTA";
import Hero from "../../sections/app/Hero";
import Features from "../../sections/app/Features";
import Content1 from "../../sections/app/Content1";
import Content2 from "../../sections/app/Content2";
import Content3 from "../../sections/app/Content3";
import Screenshots from "../../sections/app/Screenshots";
import Promo from "../../sections/app/Promo";

const App = () => {
  return (
    <>
      <PageWrapper>
        <Hero className="position-relative pt-25 pt-md-31 pt-lg-35 pb-14 pb-md-19 pb-lg-33 position-relative overflow-hidden" />
        <CTA className="bg-buttercup py-10" />
        <Features className=" bg-default-6" />
        <Content3 className="pt-23 pt-md-25 pb-11 pb-md-14 pb-lg-33 overflow-hidden position-relative" />
        <Content2 className="bg-default-6 pt-14 pt-md-19 pt-lg-34 pb-9 pb-md-14 pb-lg-34 overflow-hidden position-relative" />
        <Content1 className="pt-23 pt-md-25 pt-lg-35 pb-9 pb-md-14 pb-lg-33 overflow-hidden position-relative" />
        <Screenshots className="bg-blue-2 pt-13 pb-13 pt-md-17 pb-md-19 pt-lg-25 pb-lg-26" />
        <Promo className="bg-default-6" />
        <CTA className="bg-buttercup py-10" />
      </PageWrapper>
    </>
  );
};
export default App;
