import React from "react";

import imgM from "../../assets/image/inner-page/png/hand.png";
import imgYS from "../../assets/image/inner-page/png/cirle-yellow-shape.png";

import iconApple from "../../assets/image/inner-page/png/apple-button.png";
import iconGoogle from "../../assets/image/inner-page/png/google-play-button.png";

const Hero = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container position-static">
        <div className="row align-items-center justify-content-center position-static">
          {/* Hero Image */}
          <div className="col-xl-6 col-lg-6 col-md-7 col-xs-8 position-static">
            <div className="l6-hero-image-group">
              <img
                className="w-100"
                src={imgM}
                alt=""
                data-aos="fade-up"
                data-aos-delay="500"
              />
              <div className="img-1">
                <img
                  className="w-100"
                  src={imgYS}
                  alt=""
                  data-aos="fade-right"
                  data-aos-delay="750"
                />
              </div>
            </div>
          </div>
          {/* End Hero Image */}
          {/* Hero Content */}
          <div className="col-lg-6 col-md-9 col-sm-11">
            <div
              className="mt-8 mt-lg-0 pr-md-11 pr-lg-0 pr-xl-10"
              data-aos="fade-left"
              data-aos-delay="750"
            >
              <h1 className="font-size-13 mb-8 pr-sm-18 pr-md-8 pr-lg-0 line-height-84">
                Ficha cuando quieras, donde sea
              </h1>
              <p className="font-size-7 mb-0 pr-sm-10 pr-md-10 pr-lg-0 pr-xl-18">
                También pueden consultar su situación en la empresa, realizar
                solicitudes de ausencias, vacaciones, permutas y equipamiento.
              </p>
              <div className="mt-9">
                <a
                  className="d-block mb-6"
                  href="https://apps.apple.com/es/app/timeview/id1530163072"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconApple} alt="" />
                </a>
                <a
                  className="d-block mb-6"
                  href="https://play.google.com/store/apps/details?id=com.timeview"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconGoogle} alt="" />
                </a>
              </div>
            </div>
          </div>
          {/* End Hero Content */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
